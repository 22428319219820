import { useCallback, useMemo } from 'react';

import DemoProjectsViewer from './demoProjectsViewer/DemoProjectsViewer';
import DemoViewer from './demoViewer/DemoViewer';

import {
    useGetBriefMutation,
    useGetTopicsMutation,
    useGetTasksMutation,
    useGetHighlightsMutation,
    useGetProjectsMutation,
    useGetShortInfoMutation,
} from 'api/routes/DemoApi';
import { useAppSelector } from 'hooks/useAppSelector';
import { demoViews } from 'store/reducers/transcriptions/transcriptAppearanceSlice';

import './DemoProcessing.scss';

const DemoProcessing: React.FC = () => {
    const { demoView } = useAppSelector((state) => state.transcriptAppearance);
    const { editedTranscript } = useAppSelector((state) => state.transcript);

    const [getBrief, { isSuccess: isBriefSuccess, isLoading: isBriefLoading, data: briefText }] = useGetBriefMutation();
    const [getTasks, { isSuccess: isTasksSuccess, isLoading: isTasksLoading, data: tasksText }] = useGetTasksMutation();
    const [getTopics, { isSuccess: isTopicsSuccess, isLoading: isTopicsLoading, data: topicsText }] =
        useGetTopicsMutation();
    const [getHighlights, { isSuccess: isHighlightsSuccess, isLoading: isHighlightsLoading, data: highlightsText }] =
        useGetHighlightsMutation();
    const [getShortInfo, { isSuccess: isShortInfoSuccess, isLoading: isShortInfoLoading, data: shortInfoText }] =
        useGetShortInfoMutation();
    const [getProjects, { isSuccess: isProjectsSuccess, isLoading: isProjectsLoading, data: projectsText }] =
        useGetProjectsMutation();

    const getTranscriptText = useCallback(() => {
        const text: string[] = [];
        editedTranscript.result[0].result.forEach((replica) => {
            replica.words.forEach((word) => {
                text.push(word[0]);
            });
        });

        return text.join(' ');
    }, [editedTranscript.result]);

    const getViewerText = useCallback(
        (type: demoViews) => {
            const text = getTranscriptText();

            switch (type) {
                case 'brief':
                    getBrief({ text });
                    break;
                case 'topics':
                    getTopics({ text });
                    break;
                case 'tasks':
                    getTasks({ text });
                    break;
                case 'highlights':
                    getHighlights({ text });
                    break;
                case 'projects':
                    getShortInfo({ text });
                    getProjects({ text });
                    break;
            }
        },
        [getBrief, getHighlights, getProjects, getShortInfo, getTasks, getTopics, getTranscriptText],
    );

    const currentView = useMemo(() => {
        switch (demoView) {
            case 'topics':
                return (
                    <DemoViewer
                        title='Topics'
                        startProcessing={() => getViewerText('topics')}
                        isRequestSuccess={isTopicsSuccess}
                        isRequestLoading={isTopicsLoading}
                    >
                        {topicsText?.result}
                    </DemoViewer>
                );
            case 'tasks':
                return (
                    <DemoViewer
                        title='Tasks'
                        startProcessing={() => getViewerText('tasks')}
                        isRequestSuccess={isTasksSuccess}
                        isRequestLoading={isTasksLoading}
                    >
                        {tasksText?.result}
                    </DemoViewer>
                );
            case 'brief':
                return (
                    <DemoViewer
                        title='Brief'
                        startProcessing={() => getViewerText('brief')}
                        isRequestSuccess={isBriefSuccess}
                        isRequestLoading={isBriefLoading}
                    >
                        {briefText?.result}
                    </DemoViewer>
                );
            case 'highlights':
                return (
                    <DemoViewer
                        title='Highlights'
                        startProcessing={() => getViewerText('highlights')}
                        isRequestSuccess={isHighlightsSuccess}
                        isRequestLoading={isHighlightsLoading}
                    >
                        {highlightsText?.result}
                    </DemoViewer>
                );
            case 'projects':
                return (
                    <DemoViewer
                        title='Projects'
                        startProcessing={() => getViewerText('projects')}
                        isRequestSuccess={isProjectsSuccess && isShortInfoSuccess}
                        isRequestLoading={isProjectsLoading || isShortInfoLoading}
                    >
                        <DemoProjectsViewer
                            shortInfo={shortInfoText?.result || ''}
                            projectsList={projectsText?.result || []}
                            getTranscriptText={getTranscriptText}
                        />
                    </DemoViewer>
                );
        }
    }, [
        demoView,
        isTopicsSuccess,
        isTopicsLoading,
        topicsText?.result,
        isTasksSuccess,
        isTasksLoading,
        tasksText?.result,
        isBriefSuccess,
        isBriefLoading,
        briefText?.result,
        isHighlightsSuccess,
        isHighlightsLoading,
        highlightsText?.result,
        isProjectsSuccess,
        isShortInfoSuccess,
        isProjectsLoading,
        isShortInfoLoading,
        shortInfoText,
        projectsText?.result,
        getTranscriptText,
        getViewerText,
    ]);

    if (!demoView) {
        return <></>;
    }

    return (
        <>
            <div className='demo-viewer'>{currentView}</div>
        </>
    );
};

export default DemoProcessing;
