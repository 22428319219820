import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isWithinInterval } from 'date-fns';

import { RequestPayload, TranscriptPayload, SearchTranscriptPayload } from 'api/Models';
import { apiApi } from 'api/routes/ApiApi';

interface ArchiveState {
    records: TranscriptPayload[];
    requests: RequestPayload[];
    filteredRecords: SearchTranscriptPayload[] | TranscriptPayload[];
    isFiltered: boolean;
    filteredDate: { start: Date; end: Date } | null;
}

const slice = createSlice({
    name: 'archive',
    initialState: {
        records: [],
        requests: [],
        filteredRecords: [],
        isFiltered: false,
        filteredDate: null,
    } as ArchiveState,
    reducers: {
        setFilteredDate: (state, { payload: date }: PayloadAction<[string, string] | null>) => {
            state.filteredDate = date && { start: new Date(date[0]), end: new Date(date[1]) };
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(apiApi.endpoints.results.matchFulfilled, (state, { payload }) => {
            state.records = payload.results;
            state.isFiltered = !!state.filteredDate;
        });
        builder.addMatcher(apiApi.endpoints.filteredResults.matchFulfilled, (state, { payload }) => {
            state.filteredRecords = payload.results;
            state.isFiltered = true;
        });
        builder.addMatcher(apiApi.endpoints.requests.matchFulfilled, (state, { payload }) => {
            state.requests = payload.results.filter(
                (item) => item.task_status && item.task_status !== 'success' && item.task_status !== 'revoked',
            );
        });
        builder.addMatcher(apiApi.endpoints.search.matchFulfilled, (state, { payload }) => {
            const filterDate = state.filteredDate;
            if (filterDate) {
                state.filteredRecords = payload.results.filter((res) =>
                    isWithinInterval(new Date(res.timestamp), filterDate),
                );
            } else {
                state.filteredRecords = payload.results;
            }

            state.isFiltered = true;
        });
    },
});

export const { setFilteredDate } = slice.actions;
export default slice.reducer;
